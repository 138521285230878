<template>
  <div class="mobile-monitor" v-show="phoneMonitoring">
    <div class="height-17"></div>
    <diV class="mobile-header">
      <div>请使用手机微信扫描屏幕下方二维码开启手机监控</div>
      <div>考试中需一直打开小程序页面，并保持屏幕常亮，建议插上手机电源。</div>
    </diV>
    <div class="height-28"></div>
    <img :src="qrCodeUrl" />
    <div class="height-17"></div>
    <div>扫码遇到问题？</div>
    <div>1.请检查您的手机网络连接是否正常</div>
    <div>2.如果手机微信提示扫码失败，请更新微信APP至最新版后重新扫码</div>
    <div class="height-17"></div>
    <van-button type="info" @click="clickNext" style="width:140px">下一步</van-button>
    <div class="height-17"></div>
    <div v-if="canSkip" class="skip-btn" @click="clickSkip">没有手机？点此跳过这一步</div>
  </div>
</template>

<script>
// import MobileMonitorMixin from './mobile-monitor-mixin'
import { newBizidCheck, mobileCheck } from '@/api/candidate'
import { getMonitorQrcode } from '@/api/enterprise'
import { Dialog } from 'vant'

export default {
  // mixins: [MobileMonitorMixin],
  data() {
    return {
      /**
       * 监控状态
       */
      monitorStatus: false,
      /**
       * 拉流地址
       */
      monitorPlayUrl: '',
      /**
       * 微信扫码二维码
       */
      qrCodeUrl: '',

      /**
       * 定时检测定时器
       */
      intervalTimer: 0,

      /**
       * 是否开启手机监控
       * 0=否 1=是
       */
      phoneMonitoring: false,
      /**
       * 是否可跳过监控
       * 0=是 1=否
       */
      canSkip: false,
    }
  },
  created() {
    this.mobileCheck(() => {
      if (!this.phoneMonitoring) {
        this.toNextPage()
      }
      if (this.phoneMonitoring) {
        this.getMonitorQrcode()
      }
    })
  },
  beforeDestroy() {
    clearInterval(this.intervalTimer)
  },
  methods: {
    clickNext() {
      this.getMonitorStatus().then(res => {
        if (res.monitorStatus) {
          this.toNextPage()
        } else {
          this.$notify({ type: 'danger', message: '请使用微信扫描上方二维码开启手机监控' })
        }
      })
    },
    clickSkip() {
      Dialog.confirm({
        // title: `确认删除${type === 0 ? '附件 ' + attach.attachName : '链接 ' + attach.attachUrl} ?`,
        message: '跳过手机监控可能会被考官识别为有作弊风险，确定跳过这一步吗？',
      }).then(() => {
        this.toNextPage()
      })
      // this.$msgbox({
      //   title: '提示',
      //   type: 'warning',
      //   showCancelButton: true,
      //   cancelButtonText: '取消',
      //   confirmButtonText: '确定',
      //   message: '跳过手机监控可能会被考官识别为有作弊风险，确定跳过这一步吗？',
      // }).then()
    },
    toNextPage() {
      const redirectUrl = this.$route.query.redirectUrl
      const url = decodeURIComponent(redirectUrl)
      location.href = url
      // this.$router.push({ name: 'checkID' })
    },

    mobileCheck(callBack) {
      mobileCheck({ jobseekerChannelId: this.query.jobseekerChannelId }).then(res => {
        if (res && res.code === 0) {
          this.phoneMonitoring = res.data.phoneMonitoring ? true : false
          this.canSkip = res.data.phoneMonitoringJump && res.data.phoneMonitoringJump === 0 ? true : false
        }
        callBack()
      })
    },

    checkMonitorStatus(interval = 60000) {
      console.log('checkMonitorStatus', interval)
      if (!this.phoneMonitoring) return

      // this.intervalTimer = setInterval(() => {
      this.getMonitorStatus().then(res => {
        console.log('getMonitorStatus =>>>', res)
        this.monitorStatus = res.monitorStatus
        this.monitorPlayUrl = res.monitorPlayUrl
      })
      // }, interval)
    },

    getMonitorStatus() {
      return new Promise((resolve, reject) => {
        newBizidCheck({ jobseekerChannelId: this.query.jobseekerChannelId }).then(res => {
          if (res && res.code === 0) {
            const { status: monitorStatus, url: monitorPlayUrl } = res.data || {}
            this.monitorStatus = monitorStatus
            this.monitorPlayUrl = monitorPlayUrl
            resolve({ monitorStatus, monitorPlayUrl })
          } else {
            reject()
          }
        })
      })
    },

    getMonitorQrcode() {
      getMonitorQrcode({ jobseekerChannelId: this.query.jobseekerChannelId }).then(res => {
        if (res.code === 0) {
          console.log(res)
          const { qrCodeUrl = '' } = res.data || {}
          this.qrCodeUrl = qrCodeUrl
        }
      })
    },
  },
  computed: {
    query() {
      return this.$route.query
    },
  },
}
</script>

<style lang="scss" scoped>
.mobile-monitor {
  text-align: center;
  line-height: 20px;
  padding: 0 10px 0 10px;
  .mobile-header {
    background: #8996b0;
    color: #ffffff;
    padding: 10px 5px;
    border-radius: 5px;
    // max-width: 300px;
    margin: auto;
  }

  img {
    width: 260px;
    height: 260px;
    object-fit: contain;
  }
  .height-17 {
    height: 17px;
  }
  .height-28 {
    height: 28px;
  }
  .skip-btn {
    color: #1989fa;
    cursor: pointer;
  }
}
</style>
